<template>
    <ion-page>
      <ion-content :fullscreen="true" :scroll-events="true">
        <div id="container">
          <ion-row style="margin-top: 16px; margin-left: 16px">
            <ion-text style="font-size: 137%; font-weight: semi-bold;">
                Indicadores de Sustentabilidad
            </ion-text>
          </ion-row>
          <ion-card color="light">
            <ion-card-content color="light">
              <ion-tabs>
                <ion-tab-bar slot="top" :selected-tab="tabOption">
                  <ion-tab-button
                    @click="tabOption = 'Impacto'"
                    tab="Impacto"
                    style="font-weight: bold"
                  >
                    Impacto Ambiental
                  </ion-tab-button>
                  <ion-tab-button
                    @click="tabOption = 'Rendimiento'"
                    tab="Rendimiento"
                    style="font-weight: bold"
                  >
                    Rendimiento
                  </ion-tab-button>
                  <ion-tab-button
                    @click="tabOption = 'Emision'"
                    tab="Emision"
                    style="font-weight: bold"
                  >
                    Emision de Carbono
                  </ion-tab-button>
                </ion-tab-bar>
              </ion-tabs>
  
              <br />
              <br />
              <!-- Aca se renderizan las cards dependiendo el tab seleccionado -->
              <!-- <SustentabilidadImpacto v-if="tabOption === 'Impacto'" />
              <SustentabilidadRendimiento v-if="tabOption === 'Rendimiento'" />
              <SustentabilidadEmision v-if="tabOption === 'Emision'" /> -->
    
            </ion-card-content>
          </ion-card>
        </div>
      </ion-content>
    </ion-page>
  </template>
  
  <script>
  import {
    IonText,
    IonRow,
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonContent,
    IonPage
  } from "@ionic/vue";
  import { mapGetters } from "vuex";
  // import SustentabilidadImpacto from "@/components/informes/SustentabilidadImpacto.vue";
  // import SustentabilidadRendimiento from "@/components/informes/SustentabilidadRendimiento.vue";
  // import SustentabilidadEmision from "@/components/informes/SustentabilidadEmision.vue";
  
  export default {
    components: {
      IonContent,
      IonText,
      IonTabs,
      IonTabBar,
      IonTabButton,
      IonPage,
      IonRow,
      // SustentabilidadImpacto,
      // SustentabilidadRendimiento,
      // SustentabilidadEmision
    },
    middleware: ["authenticated", "auth_cliente"],
    data: () => ({
      tabOption: ""
    }),
  
    computed: {
      ...mapGetters(["isAdmin"])
    },
  
    //* Selecciona el tab por defecto
    async mounted() {
      this.$nextTick(() => {
        this.tabOption = "Impacto";
      });
    },
  
    methods: {
      volver() {
        this.$router.back();
      }
    }
  };
  </script>
  
  <style scoped>
  ion-tabs {
    max-height: 150px;
  }
  .margen8px {
    margin-left: 8px;
  }
  
  .margen8pxArriba {
    margin-top: 8px;
  }
  
  .leftMargin2vh {
    margin-left: 2vh;
  }
  
  .imgFocus {
    cursor: pointer;
  }
  
  @media only screen and (min-width: 1024px) {
    #container {
      width: 50% !important;
      overflow: hidden !important;
      margin-left: 43px;
    }
  }
  
  ion-tab-button {
    --background: rgba(var(--ion-color-primary-rgb), 0.3);
    --ripple-color: var(--ion-color-primary);
    --color: var(--ion-color-dark);
    --color-selected: var(--ion-color-primary);
  }
  
  ion-tab-button.tab-selected {
    --background: var(--ion-color-primary) !important;
    --ripple-color: var(--ion-color-light);
    --color-selected: var(--ion-color-dark);
  }
  
  ion-tab-bar {
    justify-content: center;
  }
  
  ion-card {
    margin-top: 16px;
  }
  </style>
  